import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import Mmenu from 'mmenu-js';
import './scss/custom.scss';


// COOKIE BANNER
var dropCookie = true;                      // false disables the Cookie, allowing you to style the banner
var cookieDuration = 14;                    // Number of days before the cookie expires, and the banner reappears
var cookieName = 'complianceCookie';        // Name of our cookie
var cookieValue = 'on';                     // Value of cookie
function createDiv(){
	var bodytag = document.getElementsByClassName('content-banner')[0];
	var div = document.createElement('div');
	div.setAttribute('id','cookie-law');
	div.innerHTML = '<p>Our website uses cookies. By continuing we assume your permission to deploy cookies, as detailed in our <a href="/about/privacy-policy" rel="nofollow" title="Privacy &amp; Cookies Policy" style="text-decoration: underline !important">privacy and cookies policy</a>. <a class="close-cookie-banner" href="javascript:void(0);" onclick="removeMe();" style="text-decoration: underline !important"><span>[CLOSE]</span></a></p>';
	bodytag.insertBefore(div,bodytag.firstChild);
	document.getElementsByTagName('body')[0].className+=' cookiebanner';
	createCookie(window.cookieName,window.cookieValue, window.cookieDuration);
}
function createCookie(name,value,days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime()+(days*24*60*60*1000));
		var expires = "; expires="+date.toGMTString();
	}
	else var expires = "";
	if(window.dropCookie) {
		document.cookie = name+"="+value+expires+"; path=/";
	}
}
function checkCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}
function eraseCookie(name) {createCookie(name,"",-1);}

// MMENU
document.addEventListener('DOMContentLoaded', () => {
	const menu = new Mmenu('#my-menu');
	const api = menu.API;

	document.querySelector('#mmenu-trigger').addEventListener('click', (e) => {
		e.preventDefault();
		api.open();
	});
});

// VIDEO MODAL
$('#myModal').on('hidden.bs.modal', function (e) {
	// Get the video element
	var video = document.getElementById('myVideo');
	console.log('video paused');
	video.pause();
	video.currentTime = 0;
});